/**
 * @fileoverview CTF Scoreboard Main Class.
 */

import { getDatabase, ref, child } from '../_snowpack/pkg/firebase/database.js'

import Database from './database.js'
import UI from './ui.js'
import TaskList from './tasklist.js'
import TeamList from './teamlist.js'
import Readme from './readme.js'
import Quest from './quest.js'
import Session from './session.js'
import Theme from './theme.js'


function countdownTimer(start) {
  const difference = start - Date.now();
  if (difference > 0) {
    const parts = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
    let remaining = Object.keys(parts).map(part => {
      return `${parts[part]} ${part}`;  
    }).join(" ");
    document.getElementById("timecount").innerHTML = "<h1>Game Start<br>" + remaining + "</h1>";
    setTimeout(countdownTimer, 1000, start);
  } else {
    window.location.reload();
  }
}

export default class Scoreboard {
  constructor () {
    this.session = null
    this.quest = null
  }
  async init () {
    const db = getDatabase()
    const database = new Database(ref(db))
    const gameStart = database.getStart()
    
    gameStart.then((start) => {
      if (Date.now() > start) {
        const ui = new UI(this)
        const taskList = new TaskList(database, ui)
        const teamList = new TeamList(database, ui, taskList, window)
        const readme = new Readme(database, ui)
        const quest = new Quest(database, ui)
        const loadScoreboard = () => {
          teamList.loadTeams()
          taskList.loadTasks()
          readme.loadReadme()
          quest.loadTasks()
          this.quest = quest
          this.session = new Session(database)
          let materialUpgrader = () => {}
          if (window.componentHandler) {
            materialUpgrader = window.componentHandler.upgradeElement.bind(
              window.componentHandler
            )
          }
          new Theme(ui, window, materialUpgrader).init()
        }
        const inlineScript = document.createElement('script')
        inlineScript.src = 'js/game.js'
        inlineScript.onload = () => {
          try {
            soundIconReset()
          } catch (e) {}
          loadScoreboard()
        }
        inlineScript.onerror = () => {
          loadScoreboard()
        }
        document.head.append(inlineScript)
      } else {
        countdownTimer(start);
      }
    })
  }
}
