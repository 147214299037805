/**
 * @fileoverview Initializes the scoreboard.
 */


import { initializeApp } from '../_snowpack/pkg/firebase/app.js'
import Scoreboard from './scoreboard.js'

const firebaseConfig = {
  apiKey: "AIzaSyAFnYXvU__X6pBBWvzfvcKUkyidaNTicCw",
  authDomain: "cyberdefender-2023.firebaseapp.com",
  databaseURL: "https://cyberdefender-2023.firebaseio.com/",
  projectId: "cyberdefender-38fe1",
  storageBucket: "cyberdefender-38fe1.appspot.com",
  messagingSenderId: "554171439653",
  appId: "1:554171439653:web:fa18f27891df9d492b3dcc"
};

initializeApp(firebaseConfig);

new Scoreboard().init()
